import { SideMenuContext } from "@equiem/lib";
import React, { useContext, useEffect } from "react";
import { Menu } from "../components/shared/Menu";
import { PortfolioProvider } from "../contexts/PortfolioProvider";

export const Dashboard = () => {
  const { setSiteSwitcherEnabled } = useContext(SideMenuContext);

  useEffect(() => {
    setSiteSwitcherEnabled(false);
    // Important to re-enable site switching.
    return () => setSiteSwitcherEnabled(true);
  }, [setSiteSwitcherEnabled]);

  return (
    <PortfolioProvider>
      <Menu />
    </PortfolioProvider>
  );
};
