import { SideMenuContext } from "@equiem/lib";
import React, { useContext, useEffect } from "react";
import { Articles as ArticlesComponent } from "../components/articles/Articles";
import { PortfolioProvider } from "../contexts/PortfolioProvider";

export const Articles = () => {
  const { setSiteSwitcherEnabled } = useContext(SideMenuContext);

  useEffect(() => {
    setSiteSwitcherEnabled(false);
    // Important to re-enable site switching.
    return () => setSiteSwitcherEnabled(true);
  }, [setSiteSwitcherEnabled]);

  return (
    <PortfolioProvider>
      <ArticlesComponent />
    </PortfolioProvider>
  );
};
