import { SideMenuContext } from "@equiem/lib";
import React, { useContext, useEffect } from "react";
import { NewArticle as NewArticleComponent } from "../components/articles/NewArticle";
import { PortfolioProvider } from "../contexts/PortfolioProvider";

export const NewArticle = () => {
  const { setSiteSwitcherEnabled } = useContext(SideMenuContext);

  useEffect(() => {
    setSiteSwitcherEnabled(false);
    // Important to re-enable site switching.
    return () => setSiteSwitcherEnabled(true);
  }, [setSiteSwitcherEnabled]);

  return (
    <PortfolioProvider>
      <NewArticleComponent />
    </PortfolioProvider>
  );
};
